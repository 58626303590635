body {
  padding: 0;
  margin: 0;
}

@keyframes scroll {
  to {
    background-position: 0px 0px;
  }
}
@keyframes point {
  from {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(2px);
  }
  to {
    transform: translateX(-2px);
  }
}
@keyframes turn-on {
  0% {
    animation-timing-function: $ease-in-quint;
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
  25% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  90% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
}

resource_holder {
  cache: url("assets/logo.png"), url("assets/mine.jpg"),
    url("assets/pattern-diagonal.png");
}

:root {
  --overlay-scrollbar-thumb-color: rgba(82, 84, 216, 0.8);
  --overlay-scrollbar-track-color: transparent;
  --overlay-scrollbar-width: auto;
  --overlay-scrollbar-thumb-color-hover: rgba(82, 84, 216, 1);
  --overlay-scrollbar-track-color-hover: transparent;
  --webkit-scrollbar-thumb-color: rgba(82, 84, 216, 0.4);
  --webkit-scrollbar-width-height: 6px;
  --webkit-scrollbar-border-radius: 0px;
}

/* Mostly taken from https://github.com/StylishThemes/Overlay-Scrollbars v3.1.0 */
*:not(select) {
  scrollbar-color: var(--overlay-scrollbar-thumb-color)
    var(--overlay-scrollbar-track-color) !important;
  scrollbar-width: var(--overlay-scrollbar-width) !important;
}
/* Chrome and derivatives*/
::-webkit-scrollbar {
  max-width: var(--webkit-scrollbar-width-height) !important;
  max-height: var(--webkit-scrollbar-width-height) !important;
  background: var(--overlay-scrollbar-track-color) !important;
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background: var(--overlay-scrollbar-track-color) !important;
}
::-webkit-scrollbar-thumb {
  background: var(--webkit-scrollbar-thumb-color) !important;
  border-radius: var(--webkit-scrollbar-border-radius) !important;
}
::-webkit-scrollbar-corner:hover,
::-webkit-scrollbar-track:hover,
::-webkit-scrollbar-track-piece:hover {
  background: var(--overlay-scrollbar-track-color-hover) !important;
}
::-webkit-scrollbar-thumb:hover {
  background: var(--overlay-scrollbar-thumb-color-hover) !important;
}
